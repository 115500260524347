import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";

export const spanish_translation = {
    [TRANSLATION_CONSTANTS.WELL_TAKE_IT_FROM_HERE]: "Ok, nos encargaremos desde aquí",
    [TRANSLATION_CONSTANTS.TRACK_AND_MANAGE]: "Rastreé y gestione su rescate desde esta página",
    [TRANSLATION_CONSTANTS.TRACKING_YOUR_RESCUE]: "Rastree su rescate",
    [TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER]: "Estamos buscando a un rescatista cerca de usted",
    [TRANSLATION_CONSTANTS.WELL_LET_YOU_KNOW]: "Le avisaremos tan pronto en cuanto asignemos a alguien para ayudar.",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE]: "cancelar rescate",
    [TRANSLATION_CONSTANTS.REQUEST_SUMMARY]: "Resumen de la solicitud",
    [TRANSLATION_CONSTANTS.REFERENCE_NUMBER]: "Número de referencia",
    [TRANSLATION_CONSTANTS.PICKUP_LOCATION]: "Lugar de rescate",
    [TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION]: "Ubicación del incidente",
    [TRANSLATION_CONSTANTS.DESTINATION_LOCATION]: "Lugar de entrega",
    [TRANSLATION_CONSTANTS.EXCHANGE_VEHICLE]: "Intercambio de vehículo",
    [TRANSLATION_CONSTANTS.NEW_VEHICLE_PICKUP_LOCATION]: "Lugar de recogida del vehículo de reemplazo",
    [TRANSLATION_CONSTANTS.NEW_VEHICLE_DROP_0FF_LOCATION]: "Lugar de entrega del vehículo de reemplazo",
    [TRANSLATION_CONSTANTS.DISABLED_VEHICLE_PICKUP_LOCATION]: "Ubicación para recoger vehículo averiado",
    [TRANSLATION_CONSTANTS.DISABLED_VEHICLE_DROP_0FF_LOCATION]: "Ubicación para entregar el vehículo averiado",
    [TRANSLATION_CONSTANTS.PROVIDER]: "Proveedor asignado",
    [TRANSLATION_CONSTANTS.CALL]: "Llamar",
    [TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER]: "Hemos encontrado a un rescatista",
    [TRANSLATION_CONSTANTS.NOTIFY_OF_PROVIDER_FOUND]: "Se ha encontrado un  proveedor de servicios. Le avisaremos cuando esté en camino.",
    [TRANSLATION_CONSTANTS.RESCUER_ON_WAY]: "El rescatista está en camino",
    [TRANSLATION_CONSTANTS.STAY_WITH_VEHICLE]: "Su rescatista está en camino. Continúe en su vehículo hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.RESCUER_ARRIVED]: "El rescatista ha llegado",
    [TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE]: "Ha llegado la ayuda. Confirme que su  proveedor de servicios  coincide con la siguiente información.",
    [TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED]: "¡Misión cumplida!",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETED]: "Su rescate se ha completado",
    [TRANSLATION_CONSTANTS.VEHICLE_DROPPED_OFF]: "Su vehículo ha sido entregado",
    [TRANSLATION_CONSTANTS.BACK_ON_THE_ROAD]: "Ahora solo se trata de regresarlo a la carretera.",
    [TRANSLATION_CONSTANTS.ASSIGNED]: "Asignado",
    [TRANSLATION_CONSTANTS.EN_ROUTE]: "En camino",
    [TRANSLATION_CONSTANTS.ON_SITE]: "En el sitio",
    [TRANSLATION_CONSTANTS.COMPLETE]: "Completado",
    [TRANSLATION_CONSTANTS.DROPPED_OFF]: "Entregado",
    [TRANSLATION_CONSTANTS.SERVICE_INCOMPLETE]: "Servicio incompleto",
    [TRANSLATION_CONSTANTS.GONE_ON_ARRIVAL]: "El rescatista acudió al sitio y no pudo contactarlo o no pudo localizar su vehículo. Nos comunicaremos con usted a la brevedad para verificar si aún necesita el servicio.",
    [TRANSLATION_CONSTANTS.RESCUE_STEP]: "Paso de rescate",
    [TRANSLATION_CONSTANTS.ROADSIDE_ASSISTANCE]: "Asistencia en carretera",
    [TRANSLATION_CONSTANTS.PROVIDED_BY]: "Proporcionado por",
    [TRANSLATION_CONSTANTS.PRIVACY_POLICY]: "Política de Privacidad",
    [TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION]: "Condiciones del servicio SMS",
    [TRANSLATION_CONSTANTS.NOTICE_AT_COLLECTION]: "Aviso de cobro",
    [TRANSLATION_CONSTANTS.WEBSITE_ACCESSIBILITY]: "Accesibilidad al sitio web",
    [TRANSLATION_CONSTANTS.CALCULATED_NO_GPS]: "A veces hay retrasos inesperados,  estamos buscando cumplir el siguiente tiempo estimado de llegada. Continúe en su vehículo hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.CALCULATED_GPS]: "Según la ubicación actual y el tráfico en el área, estamos buscando cumplir el siguiente tiempo estimado de llegada. Continúe en su vehículo hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.ETA_UPDATED]: "Actualización rápida, su rescatista ahora está programado para llegar en {projectedETAMinute}. Continuaremos trabajando con ellos para asegurarnos de que se mantengan en tiempo.",
    [TRANSLATION_CONSTANTS.MOBILE_APP_LABEL]: "¡Descargue la aplicación {appName} para conectarse con nosotros más tarde!",
    [TRANSLATION_CONSTANTS.SERVICE_CANCELLED]: "Servicio cancelado",
    [TRANSLATION_CONSTANTS.HELP_TEXT]: "Para obtener ayuda, abra la aplicación {appName} y llame a Driver Care.",
    [TRANSLATION_CONSTANTS.CANCEL_ERROR]: "Lo sentimos, no podemos cancelar su servicio",
    [TRANSLATION_CONSTANTS.RESCUE_STATUS_ERROR]: "Lo sentimos, en este momento no podemos recuperar la información de su rescate. Lo intentaremos nuevamente en {timeLeft} segundos.",
    [TRANSLATION_CONSTANTS.FAMILY_AND_FRIENDS]: "Permita que sus familiares y amigos rastreen su rescate y vean actualizaciones en vivo.",
    [TRANSLATION_CONSTANTS.SHARE]: "Comparta",
    [TRANSLATION_CONSTANTS.TEXT]: "Texto",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_CONFIRMATION_HEADER]: "¿Está seguro(a) de que quieres cancelar?",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_BODY]: "Si es así, ¿cuál es el motivo de la cancelación?",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT1]: "El servicio ya no es necesario",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT2]: "Tiempo estimado de llegada es inaceptable",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT3]: "Otro",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_BUTTON_TEXT]:" Si, cancelar",
    [TRANSLATION_CONSTANTS.VIN_FIND_INSTRUCTIONS]: "El número de VIN a menudo se encuentra en la puerta del lado del conductor o en el marco de la puerta. Adicional, puede verificar su tarjeta de seguro o el registro del vehículo.",
    [TRANSLATION_CONSTANTS.HOW_HELP_TEXT]: "¿Cómo podemos ayudarle?",
    [TRANSLATION_CONSTANTS.TOW]: "Grúa",
    [TRANSLATION_CONSTANTS.LOCKOUT]: "Servicio de Cerrajería Automotriz",
    [TRANSLATION_CONSTANTS.FUEL_DELIVERY]: "Entrega de combustible",
    [TRANSLATION_CONSTANTS.TIRE_CHANGE]: "Cambio de neumáticos/llanta",
    [TRANSLATION_CONSTANTS.JUMP_START]: "Arranque",
    [TRANSLATION_CONSTANTS.MORE_OPTIONS]: "Más opciones",
    [TRANSLATION_CONSTANTS.CONFIRM_DROP_OFF_LOCATION]: "Confirmar punto de entrega",
    [TRANSLATION_CONSTANTS.LOCATION_IS]: "Debido a que esta ubicación es",
    [TRANSLATION_CONSTANTS.INSTRUCTIONS_CUSTOMER_SERVICE]: "tendrá que hablar con un representante del Servicio de Atención al Cliente para solicitar su servicio.",
    [TRANSLATION_CONSTANTS.CALL_CUSTOMER_SERVICE]: "Por favor llame al servicio de atención al cliente.",
    [TRANSLATION_CONSTANTS.ESTIMATED_AMOUNT_DUE]: "Cantidad estimada pendiente",
    [TRANSLATION_CONSTANTS.MILES_INCLUDED]: "Millas Incluidas",
    [TRANSLATION_CONSTANTS.ADDITIONAL_MILES]: "Millas Adicionales",
    [TRANSLATION_CONSTANTS.MILES]: "millas",
    [TRANSLATION_CONSTANTS.MILE]: "milla",
    [TRANSLATION_CONSTANTS.OVER2]: "a partir de",
    [TRANSLATION_CONSTANTS.CONFIRM_CONTACT]: "confirmar contacto",
    [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE]: "confirmar vehículo",
    [TRANSLATION_CONSTANTS.COLOR]: "Color",
    [TRANSLATION_CONSTANTS.FUEL_TYPE]: "Tipo de Combustible",
    [TRANSLATION_CONSTANTS.UNLEADED]: "Unleaded",
    [TRANSLATION_CONSTANTS.DIESEL]: "Diesel",
    [TRANSLATION_CONSTANTS.DELIVER_UP_TO]: "Entregaremos hasta",
    [TRANSLATION_CONSTANTS.COST_FUEL_NOT_INCLUDED]: "El costo del combustible no está incluido.",
    [TRANSLATION_CONSTANTS.ADD_CONTACT]: "Agregar Contacto",
    [TRANSLATION_CONSTANTS.NAME]: "Nombre",
    [TRANSLATION_CONSTANTS.EMAIL]: "Correo Electrónico",
    [TRANSLATION_CONSTANTS.PHONE_NUMBER]: "Número de teléfono",
    [TRANSLATION_CONSTANTS.PROVIDE_PHONE_NUMBER]: "Favor de proporcionar su número de teléfono para permitir que el Centro de Asistencia Vial se comunique con usted, o para confirmar el servicio.",
    [TRANSLATION_CONSTANTS.SEND_TEXT_MESSAGES]: "Enviar mensajes de texto sobre el estado de mi rescate.",
    [TRANSLATION_CONSTANTS.CONFIRM]: "Confirmar",
    [TRANSLATION_CONSTANTS.SUBMIT_REQUEST]: "enviar solicitud",
    [TRANSLATION_CONSTANTS.SCHEDULE_A_RIDE]: "¿Necesitas que te lleven?",
    [TRANSLATION_CONSTANTS.HAVE_LYFT_READY]: "Tener un Lyft listo para ti una vez que te hayan ayudado.",
    [TRANSLATION_CONSTANTS.SCHEDULE]: "Horario",
    [TRANSLATION_CONSTANTS.HOW_EMBARRASSING]: "Que pena",
    [TRANSLATION_CONSTANTS.TECHNICAL_DIFFICULTIES]: "Tenemos dificultades técnicas y estamos trabajando para solucionarlas lo antes posible. Por favor, vuelva más tarde.",
    [TRANSLATION_CONSTANTS.CALL_HELP_NOW]: "Llamar para pedir ayuda ahora",
    [TRANSLATION_CONSTANTS.CALL_FOR_ASSISTANCE]: "Llamar para solicitar asistencia",
    [TRANSLATION_CONSTANTS.SORRY_NOT_FIND_BENEFITS]: "Lo sentimos, no podemos encontrar la información de sus beneficios",
    [TRANSLATION_CONSTANTS.CAPTURE_ACTUAL_MILEAGE]: "Ingrese el millaje real (no el millaje del viaje) en su tablero o centro de información de su vehículo.",
    [TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION]: "Ingrese su ubicación actual",
    [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_LOCATION]: "Ubicación del vehículo",
    [TRANSLATION_CONSTANTS.GEOLOCATION_DISABLED]: "Parece que los servicios de ubicación están desactivados, por favor, introduzca una dirección",
    [TRANSLATION_CONSTANTS.HOME]: "Inicio",
    [TRANSLATION_CONSTANTS.CALL_FOR_HELP]: "llamar para pedir ayuda",
    [TRANSLATION_CONSTANTS.PROVIDE_VEHICLE_INFO]: "Favor de proporcionar la información de su vehículo",
    [TRANSLATION_CONSTANTS.STATE]: "Estado",
    [TRANSLATION_CONSTANTS.ENTER_STATE]: "Ingresar el estado",
    [TRANSLATION_CONSTANTS.SELECT_A_STATE]: "Por favor, seleccione un Estado",
    [TRANSLATION_CONSTANTS.LICENSE_PLATE_NUMBER]: "Número de placa",
    [TRANSLATION_CONSTANTS.INVALID_LICENSE_PLATE_NUMBER]: "Número de placa/matrícula inválida",
    [TRANSLATION_CONSTANTS.CONTINUE]: "continuar",
    [TRANSLATION_CONSTANTS.OR]: "O",
    [TRANSLATION_CONSTANTS.SEARCH_BY_VIN]: "Buscar por número VIN",
    [TRANSLATION_CONSTANTS.VIN]: "VIN",
    [TRANSLATION_CONSTANTS.ENTER_VALID_VIN]: "Por favor, introduzca un número válido de identificación del vehículo (VIN)",
    [TRANSLATION_CONSTANTS.ODOMETER]: "Odómetro",
    [TRANSLATION_CONSTANTS.ENTER_VALID_MILEAGE]: "Por favor, introduzca un millaje válido",
    [TRANSLATION_CONSTANTS.SEARCH_BY_LICENSE_PLATE]: "Buscar por matrícula",
    [TRANSLATION_CONSTANTS.ALL_RIGHT_RESERVED]: "Todos los derechos reservados. Se aplican términos y condiciones.",
    [TRANSLATION_CONSTANTS.IS_INFORMATION_CORRECT]: "¿Esta información es correcta?",
    [TRANSLATION_CONSTANTS.YES]: "Si",
    [TRANSLATION_CONSTANTS.NO]: "No",
    [TRANSLATION_CONSTANTS.IS_WHERE_YOUR_VEHICLE_IS]: "¿Es aquí donde se encuentra su vehículo?",
    [TRANSLATION_CONSTANTS.ADD_LOCATION_DETAILS]: "Agregar detalles de su ubicación actual",
    [TRANSLATION_CONSTANTS.EXAMPLE_STREET]: "Ejemplo: nombre de la calle, tiendas cercanas, puntos de referencia, etc.",
    [TRANSLATION_CONSTANTS.SAVE]: "Guardar",
    [TRANSLATION_CONSTANTS.CHARACTERS_REMAINING]: "Caracteres restantes",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND]: "Parece que aún no se ha inscrito para los beneficios de asistencia vial. Puede pagar solo por el servicio de hoy o llamarnos para preguntar sobre una membresía.",
    [TRANSLATION_CONSTANTS.NO_BENEFITS_AVAILABLE]: "No hay beneficios disponibles",
    [TRANSLATION_CONSTANTS.DID_NOT_PURCHASE_COVERAGE]: " No compró cobertura",
    [TRANSLATION_CONSTANTS.COVERAGE_UP_TO]: "Cobertura hasta",
    [TRANSLATION_CONSTANTS.TELL_WHICH_BEST_DESCRIBES_SITUATION]: "Por favor, díganos cuál opción describe mejor su situación.",
    [TRANSLATION_CONSTANTS.STUCK_IN_DITCH]: "Estoy atrapado en una zanja",
    [TRANSLATION_CONSTANTS.CAR_NEEDS_RECHARGING]: "Mi coche eléctrico necesita recarga",
    [TRANSLATION_CONSTANTS.SELECT_TOW_LOCATION]: "Seleccione el lugar de remolque",
    [TRANSLATION_CONSTANTS.SELECT_OR_ENTER_TOW_DESTINATION]: "Seleccione o introduzca el destino de la grúa",
    [TRANSLATION_CONSTANTS.NO_REPAIR_CENTERS_FOUND]: "No podemos encontrar un centro de reparación.",
    [TRANSLATION_CONSTANTS.SELECT_ONE]: "Seleccione Uno",
    [TRANSLATION_CONSTANTS.BLACK]: "Negro",
    [TRANSLATION_CONSTANTS.BLUE]: "Azul",
    [TRANSLATION_CONSTANTS.BROWN]: "Café",
    [TRANSLATION_CONSTANTS.BURGUNDY]: "Vino",
    [TRANSLATION_CONSTANTS.GOLD]: "Dorado",
    [TRANSLATION_CONSTANTS.GREEN]: "Verde",
    [TRANSLATION_CONSTANTS.GREY]: "Gris",
    [TRANSLATION_CONSTANTS.ORANGE]: "Naranja",
    [TRANSLATION_CONSTANTS.PURPLE]: "Morado",
    [TRANSLATION_CONSTANTS.RED]: "Rojo",
    [TRANSLATION_CONSTANTS.SILVER]: "Plata",
    [TRANSLATION_CONSTANTS.WHITE]: "Blanco",
    [TRANSLATION_CONSTANTS.YELLOW]: "Amarillo",
    [TRANSLATION_CONSTANTS.WHICE_DRIVER_NEEDS_HELP]: "Por favor, seleccione qué conductor necesita ayuda hoy.",
    [TRANSLATION_CONSTANTS.VEHICLE]: "Vehículo",
    [TRANSLATION_CONSTANTS.PAYMENT]: "Pago",
    [TRANSLATION_CONSTANTS.COVERED_BY_BENEFITS]: "Cubierto por los beneficios",
    [TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED]: "El costo total del servicio se determinará una vez que se asigne un proveedor de servicios. El monto podría exceder su límite de beneficios y requerir un pago de bolsillo al proveedor de servicios.",
    [TRANSLATION_CONSTANTS.TOTAL]: "Total",
    [TRANSLATION_CONSTANTS.WHERE_ARE_YOUR_KEYS]: "¿Dónde están sus llaves?",
    [TRANSLATION_CONSTANTS.IN_MY_VEHICLE]: "En mi vehículo",
    [TRANSLATION_CONSTANTS.IN_MY_TRUNK]: "En el maletero/cajuela",
    [TRANSLATION_CONSTANTS.LOST_BROKEN]: "Perdidas / Averiadas",
    [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_INFORMATION]: "Por favor, confirme la información de su vehiculo.",
    [TRANSLATION_CONSTANTS.WE_ARE_SORRY]: "Lo sentimos",
    [TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTINUE_PPU]: "El vehículo que seleccionó actualmente no tiene cobertura en la carretera. Continúe como huésped de pago por uso.",
    [TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTACT_REPRESENTATIVE]: "La cobertura de este vehículo está suspendida. Póngase en contacto con nuestro representante para solicitar el servicio.",
    [TRANSLATION_CONSTANTS.WHICH_TIRE_IS_FLAT]: "¿Qué neumático necesita aire?",
    [TRANSLATION_CONSTANTS.HAVE_A_SPARE_TIRE]: "¿Tiene un neumático de repuesto?",
    [TRANSLATION_CONSTANTS.LEAVE_PAGE_INFO]: "Si abandona esta página, la información que haya introducido se eliminará y no se tramitará su solicitud de servicio. ¿Todavía quiere salir de esta página?",
    [TRANSLATION_CONSTANTS.VEHICLE_STOP_DRIVING]: "¿Se detuvo su vehículo mientras conducía?",
    [TRANSLATION_CONSTANTS.BATTER_MOST_LIKELY_PROBLEM]: "Es posible que pasarle corriente a su vehículo no funcione.",
    [TRANSLATION_CONSTANTS.NO_WORRIES_HAPPENS]: "No se preocupe, sucede todo el tiempo.",
    [TRANSLATION_CONSTANTS.SERVICE_CENTERS_EQUIPPED]: "Los centros de servicio están equipados para abrir maleteros/cajuelas.",
    [TRANSLATION_CONSTANTS.WITHOUT_A_SPARE_TOW_VEHICLE]: "Necesitaremos remolcar su vehículo.",
    [TRANSLATION_CONSTANTS.DEFAULT]: "Default",
    [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN]: "Como ha perdido o se le ha roto la llave, podemos remolcar su vehículo hasta su casa o hasta un taller de reparaciones.",
    [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED]: "Como ha perdido o se le ha roto la llave, podemos remolcar su vehículo hasta un taller de reparaciones.",
    [TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH]: "Si ya comprobó que hay una palanca para abrir el maletero/cajuela y/o una puerta de acceso al maletero/cajuela y aún no puede alcanzar sus llaves, tendremos que remolcar su vehículo.",
    [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE]: "Probablemente, necesite un neumático/llanta o neumáticos/llantas nuevos. Podemos remolcar su vehículo hasta su casa o un taller cercano.",
    [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED]: "Probablemente, necesite un neumático o neumáticos nuevos. Podemos remolcar su vehículo hasta un taller cercano.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE]: "Como su vehículo se paró mientras conducía, lo más probable es que su batería no sea el problema. En su lugar, podemos remolcar su vehículo a su casa o a un taller de reparaciones.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED]: "Como su vehículo se paró mientras conducía, lo más probable es que su batería no sea el problema. En su lugar, podemos remolcar su vehículo a un taller de reparaciones.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW]: "continuar remolcando",
    [TRANSLATION_CONSTANTS.STARTS_AT]: "A partir de",
    [TRANSLATION_CONSTANTS.NO_THANKS_UNLOCK_MY_VEHICLE]: "abrir mi vehículo",
    [TRANSLATION_CONSTANTS.SORRY_YOU_HAVE_REACHED]: "Lo sentimos, alcanzó su límite de",
    [TRANSLATION_CONSTANTS.LIMIT]: "límite",
    [TRANSLATION_CONSTANTS.CAN_STILL_HELP_THANK]: "Aún podemos ayudar, comuníquese con nuestro representante para solicitar servicio. Gracias.",
    [TRANSLATION_CONSTANTS.CAN_STILL_HELP]: "Todavía podemos ayudarle. Póngase en contacto con nuestro representante para solicitar servicio",
    [TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS]: "Ha agotado todos sus beneficios. Todavía podemos ayudar. Comuníquese con nuestro representante para solicitar servicio.",
    [TRANSLATION_CONSTANTS.VEHICLE_WEIGHT_LIMIT]: "Límite de peso del vehículo",
    [TRANSLATION_CONSTANTS.WEIGHT_CLASS_SPECIAL_SERVICE]: "Esta categoría de peso requiere un servicio especial. Póngase en contacto con un representante.",
    [TRANSLATION_CONSTANTS.RUN_INTO_ISSUES_BENEFITS]: "Hemos tenido un problema con sus beneficios",
    [TRANSLATION_CONSTANTS.ENTER_FIRST_LAST_NAME]: "Favor de ingresar su nombre y apellido",
    [TRANSLATION_CONSTANTS.SELECT_A_DRIVER]: "Por favor, seleccione un conductor.",
    [TRANSLATION_CONSTANTS.ENTER_VALID_EMAIL_ADDRESS]: "Favor de ingresar una dirección de correo electrónico válida.",
    [TRANSLATION_CONSTANTS.ENTER_PHONE_NUMBER]: "Favor de ingresar su número de teléfono.",
    [TRANSLATION_CONSTANTS.PAYMENT_INFO]: "Información de pago",
    [TRANSLATION_CONSTANTS.ENTER_DIGIT_ZIP_CODE]: "Favor de ingresar un código postal de 5 dígitos.",
    [TRANSLATION_CONSTANTS.DRIVER]: "Conductor",
    [TRANSLATION_CONSTANTS.BILLING_ZIP]: "Código postal de facturación",
    [TRANSLATION_CONSTANTS.CONTACT_PAYMENT]: "contacto/pago",
    [TRANSLATION_CONSTANTS.TAKE_UP_5_DAYS_CREDIT_REFUND]: "Por favor, tenga en cuenta que el reembolso de su tarjeta de crédito puede tardar hasta 5 días en procesarse.",
    [TRANSLATION_CONSTANTS.CANCEL_REQUEST_SUCCESFULLY]: "Su solicitud de cancelación ha sido procesada con éxito.",
    [TRANSLATION_CONSTANTS.APPRECIATE_FEEDBACK]: "Agradecemos sus comentarios. Muchas gracias!",
    [TRANSLATION_CONSTANTS.CONTINUE_AS_GUEST]: "Continuar como invitado(a)",
    [TRANSLATION_CONSTANTS.USE_PPU_OPTION_TO_PAY]: "Utilice nuestra opción de pago por uso para pagar la asistencia vial que necesita hoy.",
    [TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED_SHARE_FEEDBACK]: "¡Misión cumplida! Favor de compartir sus sugerencias a continuación.",
    [TRANSLATION_CONSTANTS.QUICK_UPDATE]: "Actualización rápida, su rescatador está a punto de llegar a",
    [TRANSLATION_CONSTANTS.WE_WILL_CONTINUE_TO_WORK]: " Seguiremos trabajando con ellos para asegurarnos de que siguen por el buen camino.",
    [TRANSLATION_CONSTANTS.WE_CAN_HELP]: "Podemos ayudarle",
    [TRANSLATION_CONSTANTS.GIVE_US_A_CALL]: "Por favor, danos una llamada para que podamos ponerte en camino.",
    [TRANSLATION_CONSTANTS.LOOKS_LIKE_CAN_NOT_CHARGE]: "Parece que no podemos cargar su vehículo, pero podemos ayudarle",
    [TRANSLATION_CONSTANTS.CONTINUE_LIKE_US_TO_TOW]: "Continúe si desea que remolquemos su vehículo hasta su domicilio o hasta una estación de carga cercana.",
    [TRANSLATION_CONSTANTS.INCLUDED]: "Incluido",
    [TRANSLATION_CONSTANTS.ADDITIONAL]: "Adicional",
    [TRANSLATION_CONSTANTS.OVER]: "más de",
    [TRANSLATION_CONSTANTS.WEBSITE]: "Sitio web",
    [TRANSLATION_CONSTANTS.REVIEW_SUMMARY]: "Revisar resumen",
    [TRANSLATION_CONSTANTS.REVIEWS]: "Reseñas",
    [TRANSLATION_CONSTANTS.REPAIR_SHOP_NOT_BEEN_REVIEWED]: "Este taller no ha sido revisado.",
    [TRANSLATION_CONSTANTS.SHARE_YOUR_FEEBACK]: "Por favor, comparta sus comentarios a continuación.",
    [TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_RESCUER]: "¿Cómo fue su experiencia con el rescatista?",
    [TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_SITE]: "¿Cómo fue su experiencia con nuestro Centro de Asistencia Vial?",
    [TRANSLATION_CONSTANTS.SHARE_ANY_ADDITIONAL_COMMENTS]: "Favor de compartir cualquier comentario adicional a continuación",
    [TRANSLATION_CONSTANTS.SUBMIT]: "Enviar",
    [TRANSLATION_CONSTANTS.SKIP]: "Saltar",
    [TRANSLATION_CONSTANTS.BENEFITS]: "Beneficios",
    [TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED_PROVIDER]: "El coste final se determinará una vez asignado el proveedor. Toda cantidad que supere sus beneficios deberá abonarse directamente al proveedor de servicios.",
    [TRANSLATION_CONSTANTS.SERVICE_NOT_AVAILABLE]: "El servicio aún no está disponible",
    [TRANSLATION_CONSTANTS.WE_SORRY_SERVICE_NOT_AVAILABLE]: "Lo sentimos, el servicio aún no está disponible en esta zona a través de esta aplicación.",
    [TRANSLATION_CONSTANTS.ENTER_ADDITIONAL_DETAILS_CURRENT_LOCATION]: "Por favor, introduzca detalles adicionales sobre su ubicación actual o lugar de entrega",
    [TRANSLATION_CONSTANTS.I_E_STREET_NAME]: "Por ejemplo: nombre de la calle, número y código postal",
    [TRANSLATION_CONSTANTS.OK]: "Ok",
    [TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION_LINK]: "https://roadsidemobile.com/tac/sms",
    [TRANSLATION_CONSTANTS.SECURITY_FACILITY]: "pero como esta es una instalación segura puede continuar con su solicitud de remolque",
    [TRANSLATION_CONSTANTS.THIS_LOCATION_IS]: "Esta ubicación es",
    [TRANSLATION_CONSTANTS.CLOSED]: "cerrado",
    [TRANSLATION_CONSTANTS.CLOSING_SOON]: "cierra proximamente",
    [TRANSLATION_CONSTANTS.SUSPENDED_VEHICLE_TO_CALL_CENTER]: "Ha seleccionado un vehículo que no tiene cobertura para una {serviceType}. Aún así podemos ayudarle. Póngase en contacto con nuestro representante para solicitar el servicio.",
    [TRANSLATION_CONSTANTS.POLICY_NUMBER]: "Número de póliza",
    [TRANSLATION_CONSTANTS.MEMBER_NUMBER]: "Número de afiliado",
    [TRANSLATION_CONSTANTS.FIND_POLICY_NUMBER]: "Puede encontrar su número de póliza en el frente de su tarjeta.",
    [TRANSLATION_CONSTANTS.FIND_MEMBER_NUMBER]: "Puede encontrar su número de afiliación en el frente de su tarjeta.",
    [TRANSLATION_CONSTANTS.LOST_POLICY_NUMBER]: "Si ha perdido su número de póliza y/o tarjeta, puede llamar a nuestro Centro de Atención al Cliente al",
    [TRANSLATION_CONSTANTS.LOST_MEMBER_NUMBER]: "Si ha perdido su número de membresía y / o tarjeta, llame a nuestro Centro de Servicio al Cliente al",
    [TRANSLATION_CONSTANTS.MONDAY_FRIDAY]: "Lunes - Viernes",
    [TRANSLATION_CONSTANTS.WHAT_IS_THIS]: "¿Qué es esto?",
    [TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS]: "Necesitamos información para encontrar sus beneficios",
    [TRANSLATION_CONSTANTS.PHONE_LOOKUP_FAILURE]: "Lo sentimos, no hemos podido localizar sus prestaciones con su número de teléfono.",
    [TRANSLATION_CONSTANTS.FN_LN_ZIP_LOOKUP_FAILURE]: "Lo sentimos, no hemos podido localizar sus beneficios con su nombre, apellidos y código postal.",
    [TRANSLATION_CONSTANTS.NAME_STATE_ZIP_LOOKUP_FAILURE]: "Lo sentimos, no hemos podido localizar sus beneficios con su nombre, estado  y código postal.",
    [TRANSLATION_CONSTANTS.FIRST_NAME]: "Nombre",
    [TRANSLATION_CONSTANTS.LAST_NAME]: "Apellido",
    [TRANSLATION_CONSTANTS.ZIP]: "Código postal",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_FIRST_NAME]: "Favor de ingresar su primer nombre.",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_LAST_NAME]: "Favor de ingresar su apellido.",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_ACCOUNT]: "Favor introduzca su información exactamente como aparece en su cuenta (por ejemplo, Michael Smith vs. Mike Smith)",
    [TRANSLATION_CONSTANTS.ZIP_CODE]: "Código postal",
    [TRANSLATION_CONSTANTS.AS_APPEARS_ACCOUNT]: "(Como aparece en su cuenta)",
    [TRANSLATION_CONSTANTS.UP_TO]: "Hasta",
    [TRANSLATION_CONSTANTS.FUEL_PRICE]: "Precio del combustible",
    [TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS_TO_PAY]: "Ha agotado todos sus beneficios. Para pagar el servicio de hoy, elija entre las siguientes opciones.",
    [TRANSLATION_CONSTANTS.VEHICLE_INFO]: "Información del vehiculo",
    [TRANSLATION_CONSTANTS.ADD_VEHICLE]: "Añadir vehículo",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_CAR_MODEL]: "Favor de Ingresar una fecha de modelo de automóvil posterior a 1970 y anterior a",
    [TRANSLATION_CONSTANTS.YEAR]: "Año",
    [TRANSLATION_CONSTANTS.MAKE_MODEL]: "Marca / Modelo",
    [TRANSLATION_CONSTANTS.YYYY]: "AAAA",
    [TRANSLATION_CONSTANTS.ADD]: "Agregar",
    [TRANSLATION_CONSTANTS.ERROR_MEMBERSHIP_NUMBER]: "Introduzca su número de afiliación de 9 o 10 dígitos.",
    [TRANSLATION_CONSTANTS.ERROR_POLICY_NUMBER]: "Favor de ingresar un número de póliza que comience con 4 caracteres y termine con 9 dígitos. Por ejemplo: XXXX123456789",
    [TRANSLATION_CONSTANTS.PLEASE_CONFIRM_VEHICLE]: "Favor de confirmar su vehículo O inicié una nueva búsqueda por número de VIN.",
    [TRANSLATION_CONSTANTS.FOUND]: "Se ha encontrado",
    [TRANSLATION_CONSTANTS.VEHICLES]: "Vehículo(s)",
    [TRANSLATION_CONSTANTS.PLEASE_SELECT_OPTION]: "Favor seleccionar una opción",
    [TRANSLATION_CONSTANTS.ESTIMATED_MILEAGE]: "Millaje estimado",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_VALID_ESTIMATED_MILEAGE]: "Favor ingrese un millaje valido",
    [TRANSLATION_CONSTANTS.MEMBERSHIP_2_DAY_WAIT_PERIOD]: "Su afiliación aún no es elegible para la cobertura en carretera debido al periodo de espera de 2 días. Para pagar el servicio de hoy, elija una de las siguientes opciones",
    [TRANSLATION_CONSTANTS.CLAIMS_USED_UP]: "Solicitudes agotadas",
    [TRANSLATION_CONSTANTS.CANCEL]: "Cancelar",
    [TRANSLATION_CONSTANTS.MOTOR_VEHICLE_NUMBER]: "Número de identificación del vehículo (MVA No.)",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_MVA]: "Su número de MVA ( Número de identificación del vehículo) se puede encontrar en la etiqueta de la llave",
    [TRANSLATION_CONSTANTS.ALLSTATE_FOOTER_TEXT]: "Compañía de Seguros Allstate",
    [TRANSLATION_CONSTANTS.MANAGE_MEMBERSHIP]: "Administrar membresía",
    [TRANSLATION_CONSTANTS.WHEN_HELP_TEXT]: "¿Cuándo podemos ayudarle?",
    [TRANSLATION_CONSTANTS.GET_HELP_NOW]: "Obtenga ayuda ahora",
    [TRANSLATION_CONSTANTS.SCHEDULE_LATER]: "Programar una hora más tarde",
    [TRANSLATION_CONSTANTS.SERVICE_DATE]: "Fecha de servicio",
    [TRANSLATION_CONSTANTS.PREFERRED_TIME]: "Hora preferida",
    [TRANSLATION_CONSTANTS.PICK_A_TIME]: "Seleccione una hora",
    [TRANSLATION_CONSTANTS.ETA_SUGGESTION]: "La hora estimada de llegada se le comunicará en cuanto se reserve el proveedor el día del servicio.",
    [TRANSLATION_CONSTANTS.CONFIRM_DATE_TIME]: "Confirmar fecha y hora",
    [TRANSLATION_CONSTANTS.PRIMER_HELP]: "Ayúdenos a encontrarle rápidamente",
    [TRANSLATION_CONSTANTS.SHARING_PHONE]: "La forma <strong>más rápida</strong> y <strong>precisa</strong> de que le encuentre el Servicio de Asistencia en Carretera es compartiendo la ubicación en su teléfono.",
    [TRANSLATION_CONSTANTS.CLICK_ALLOW]: "Presione <strong>Permitir</strong> en la siguiente pantalla para compartir el siguiente permiso:",
    [TRANSLATION_CONSTANTS.LOCATION]: "Ubicación",
    [TRANSLATION_CONSTANTS.PRIMER_DATA]: "Sus datos y su ubicación serán compartidos con su proveedor de servicios y sólo se utilizarán para este rescate.",
    [TRANSLATION_CONSTANTS.CLOSING_SOON_OPEN_UNTIL]: "cierra proximamente • Abierto hoy hasta las {time}",
    [TRANSLATION_CONSTANTS.OPEN_UNTIL]: "Abierto hoy hasta las {time}",
    [TRANSLATION_CONSTANTS.TOW_DESTINATION_CLOSED]: "cerrado",
    [TRANSLATION_CONSTANTS.A_WEEK_AGO]: "hace una semana",
    [TRANSLATION_CONSTANTS.X_WEEKS_AGO]: "hace {reviewTime} semanas",
    [TRANSLATION_CONSTANTS.A_MONTH_AGO]: "hace un mes",
    [TRANSLATION_CONSTANTS.X_MONTHS_AGO]: "hace {reviewTime} meses",
    [TRANSLATION_CONSTANTS.A_YEAR_AGO]: "hace un año",
    [TRANSLATION_CONSTANTS.X_YEARS_AGO]: "hace {reviewTime} años",
    [TRANSLATION_CONSTANTS.COMPLIANCE_CONFIRMATION]: "Al presionar <strong>Confirmar</strong> a continuación, usted acepta que su ubicación e información de contacto sean compartidas con su proveedor de servicios.",
    [TRANSLATION_CONSTANTS.CONFIRM]: "Confirmar",
    [TRANSLATION_CONSTANTS.SORRY_TO_SEE_YOU_GO]: "Lamento que se vaya",
    [TRANSLATION_CONSTANTS.CALL_FOR_HELP_DESCRIPTION]: "Antes de comunicarle, seleccione una de las opciones siguientes o proporcione más detalles en la casilla",
    [TRANSLATION_CONSTANTS.ISSUE_IN_ROADSIDE_COVERAGE]: "Hay un problema con mi cobertura de asistencia en carretera",
    [TRANSLATION_CONSTANTS.SCHEDULE_SERVICE_FOR_FUTURE_TIME]: "Quiero hacer una cita para un servicio en el futuro",
    [TRANSLATION_CONSTANTS.TALK_TO_PERSON]: "Solo quiero hablar con una persona",
    [TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION_WRONG]: "La ubicación donde necesito asistencia es incorrecta",
    [TRANSLATION_CONSTANTS.OTHER_SPECIFY]: "Otro, por favor especifique ",
    [TRANSLATION_CONSTANTS.GO_BACK]: "regresar",
    [TRANSLATION_CONSTANTS.CLOCK_UPDATED]: "Esta página se actualizó por última vez el {{h:mm:ss A}}",
    [TRANSLATION_CONSTANTS.ETA_CALCULATING]: "Hora estimada de llegada: cálculo",
    [TRANSLATION_CONSTANTS.FINDING_RESCUER]: "Buscando rescatista",
    [TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC]: "Estamos buscando un  proveedor de servicios cerca de su ubicación. Puede tardar hasta 15 minutos. Permanezca en un lugar seguro hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.ETA]: "Hora estimada de llegada",
    [TRANSLATION_CONSTANTS.CONTACT]: "Contacto",
    [TRANSLATION_CONSTANTS.APPOINTMENT_CONFIRMED]: "Cita confirmada",
    [TRANSLATION_CONSTANTS.HAPPEN_NEXT]: "Esto es lo que ocurre a continuación",
    [TRANSLATION_CONSTANTS.APPOINTMENT_SCHEDULED]: "Su cita está programada. Le enviaremos una confirmación por mensaje de texto un par de horas antes del inicio de su cita. También recibirá mensajes de texto cuando el servicio esté en camino.",
    [TRANSLATION_CONSTANTS.VEHICLE_LOCATION]: "Ubicación del vehículo",
    [TRANSLATION_CONSTANTS.PAYMENT_ISSUE]: "Si hay algún problema con su pago, un representante del servicio de atención al cliente se pondrá en contacto con usted ",
    [TRANSLATION_CONSTANTS.NEED_CHANGES]: "¿Necesita hacer cambios?",
    [TRANSLATION_CONSTANTS.ANYTHING_HAPPENS]: "Si algo cambia, puede cancelar su solicitud de rescate presionando cancelar rescate que aparece a continuación y enviar una nueva solicitud con los datos de rescate correctos.",
    [TRANSLATION_CONSTANTS.APPOINTMENT_CANCELLED]: "Cita cancelada",
    [TRANSLATION_CONSTANTS.APPOINTMENT_CANCELLED_ERROR]: "Lo sentimos, no podemos cancelar su cita",
    [TRANSLATION_CONSTANTS.APPOINTMENT_DETAILS_ERROR]: "Lo sentimos, no podemos obtener la confirmación de su cita en este momento. Por favor, llámenos para más detalles.",
    [TRANSLATION_CONSTANTS.APPOINTMENT_DETAILS_RESPONSE_ERROR]: "Lo sentimos, no podemos obtener la información de su cita en este momento. Intentaremos de nuevo en {timeLeft} segundos.",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND_PRICING_INFO]: "Pague por su cuenta o use su servicio personal de asistencia en carretera para solicitar ayuda. <br/><br/> <u>Seleccione aquí para ver los precios.</u>",
    [TRANSLATION_CONSTANTS.INFO_TO_DISPATCH]: "Necesitamos información para enviar un rescatista",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_MEMBER_ID]: "Puede encontrar su número de miembro en el correo electrónico de registro o accediendo a su cuenta del servicio y seleccionando perfil.",
    [TRANSLATION_CONSTANTS.START_OVER_ALERT_MESSAGE]: "Para una mejor experiencia, por favor, comience de nuevo presionando el botón de solicitud de servicio en la página de asistencia en carretera.",
    [TRANSLATION_CONSTANTS.WE_CANNOT_SERVICE_TITLE]: "Lo sentimos, no podemos dar servicio de",
    [TRANSLATION_CONSTANTS.REQUEST]: "Solicitar en la aplicación",
    [TRANSLATION_CONSTANTS.MOTOR_VEHICLE_ALERT_MESSAGE]: "No podemos verificar su contrato de alquiler. Por favor llamenos",
    [TRANSLATION_CONSTANTS.FURTHER_ASSISTANCE]: "para obtener más ayuda.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TITLE]: "Vamos a resolver el problema.",
    [TRANSLATION_CONSTANTS.SEE_MORE]: "Ver más",
    [TRANSLATION_CONSTANTS.WINCH]: "Torno para remolque",
    [TRANSLATION_CONSTANTS.TOW_SERVICES]: "Servicios de Remolque",
    [TRANSLATION_CONSTANTS.LOST_KEY_TOWING]: "Llave Perdida y Grúa",
    [TRANSLATION_CONSTANTS.KEY_COST_AVG_TOW_COST]: "costo de la llave + costo promedio del remolque",
    [TRANSLATION_CONSTANTS.SERVICE_CHARGES]: "Los cargos por el servicio se facturarán en su contrato de alquiler.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_ONE_TITLE]: "Revise las luces",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_ONE_CONTENT]: "Encienda las luces interiores o los faros. Si las luces no se encienden, es probable que el problema sea la batería.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_TWO_TITLE]: "Revise el tablero de instrumentos",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_TWO_CONTENT]: "Pise el pedal del freno mientras arranca el motor. Revise el tablero y los indicadores. Si no se encienden, llame para solicitar el servicio de grúa.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_THREE_TITLE]: "Asegúrese de que el vehículo esté en park",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_THREE_CONTENT]: "Ponga el vehículo en park, pise el freno e intente arrancar el motor.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FOUR_TITLE]: "Revise el volante/timón",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FOUR_CONTENT]: "Mueva el volante/timón tanto a la derecha como a la izquierda. Puede que tenga que ejercer una ligera presión para moverlo.<br/><br/> A continuación, intente arrancar el motor pisando el freno.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FIVE_TITLE]: "Pruebe el botón de arranque",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FIVE_CONTENT]: "Acerque la llave al botón de arranque y presiónelo. Pise el freno mientras arranca el motor.<br/><br/> Si el motor arranca, puede ser necesario cambiar la pila de la llave. Puede encontrar pilas de repuesto para la llave en cualquier farmacia.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FIXED]: "Sí, eso lo arregló",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_CONTINUE]: "Continúe para obtener ayuda",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_TITLE]: "Revisar el motor",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_CONTENT]: "Si el vehículo se apaga mientras conduce, ponga el vehículo en park, pise el freno e intente arrancar el motor",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_TITLE]: "Revise las luces",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_CONTENT]: "Encienda las luces interiores o los faros. Si las luces se encienden, ponga el vehículo en park, pise el freno e intente arrancar el motor. Si el motor hace contacto, pero no arranca, es posible que necesite gasolina.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_TITLE]: "Revise el indicador de gasolina",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_CONTENT]: "Revise si el indicador de gasolina dice muy bajo o vacío.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_FINAL_CONTENT]: "Si después de comprobar estos pasos necesita entrega de gasolina, se entregarán hasta tres galones de gasolina al vehículo.",
    [TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_TITLE]: "Intente abrir el vehículo por remoto",
    [TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_CONTENT]: "La aplicación {brand} es la forma más fácil de abrir su vehículo.<br/><br/> Simplemente descargue la aplicación y siga las instrucciones para intentar abrir el vehículo de forma remota.",
    [TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_CONTENT_MEMBER]: "Unlock your vehicle using the {brand} app. <br/><br/> Return to the MyCar tab in the app to unlock your vehicle remotely.",
    [TRANSLATION_CONSTANTS.GET_THE_APP]: "Obtener la aplicación",
    [TRANSLATION_CONSTANTS.RENTAL_EXCHANGE_TITLE]: "¿Necesita un intercambio de alquiler?",
    [TRANSLATION_CONSTANTS.RENTAL_EXCHANGE_CONTENT]: "Una vez que el servicio del vehículo se haya completado, puede cambiar su vehículo de alquiler en la oficina de alquiler o en una oficina cercana. Si necesita ayuda, llame a un agente de Servicios en Carretera para que le ayude.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FIXED_ALERT_MESSAGE]: "Su servicio de asistencia en carretera ha terminado. Gracias por utilizar el servicio de asistencia en carretera.",
    [TRANSLATION_CONSTANTS.COMPANY_NAME]: "Nombre de Empresa",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_FIRST_NAME]: "Favor de ingresar su primer nombre.",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_LAST_NAME]: "Favor de ingresar su apellido.",
    [TRANSLATION_CONSTANTS.WHAT_IS_YOUR_FUEL_TYPE]: "¿Cuál es su tipo de combustible?",
    [TRANSLATION_CONSTANTS.OUT_OF_POCKET]: "Fuera de tu bolsillo {{price}}",
    [TRANSLATION_CONSTANTS.PER_RESCUE]: "/ rescate",
    [TRANSLATION_CONSTANTS.SERVICE_COVERAGE_UPTO]: "Covered up to",
    [TRANSLATION_CONSTANTS.SERVICE_COVERED]: "Covered",
    [TRANSLATION_CONSTANTS.SERVICE_BASED_BANNER]: "Your benefits offer a maximum coverage per service. Any service labeled \"out-of-pocket\" is not covered.",
    [TRANSLATION_CONSTANTS.NO_SUGGESTIONS]: "Lo sentimos. No podemos encontrar un centro de reparación. Puede intentar buscar o seleccionar de nuevo o llamar al servicio de atención al cliente.",
    [TRANSLATION_CONSTANTS.NO_SUGGESTIONS_CALL_CENTER]: "Lo sentimos. No podemos encontrar un centro de reparación. Por favor llame al servicio de atención al cliente.",
    [TRANSLATION_CONSTANTS.RETRIEVING_LOCATIONS]: "Obteniendo ubicación...",
    [TRANSLATION_CONSTANTS.LIVE_CHAT]: "chat en vivo (sólo en inglés)",
    [TRANSLATION_CONSTANTS.ENTER_MANUAL_LOCATION]: "Lo sentimos. No podemos encontrar un centro de reparación. Puede introducir una ubicación manualmente o llamar al servicio de atención al cliente.",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE]: "Vea si califica para una Tarjeta de Crédito Pep Boys sin IMPACTO en su puntaje de crédito.",
    [TRANSLATION_CONSTANTS.ENTER_COMPANY_NAME]: "Por favor, introduzca el nombre de su empresa.",
    [TRANSLATION_CONSTANTS.FOOTER_COMPANY_SIGNATURE_CLUB]: "Firma Motor Club.",
    [TRANSLATION_CONSTANTS.FOOTER_COMPANY_SLOGAN]: "Allstate. Está en buenas manos.",
    [TRANSLATION_CONSTANTS.STATUS_OPEN24]: "Abierto las 24 horas",
    [TRANSLATION_CONSTANTS.STATUS_CLOSED]: "Cerrado",
    [TRANSLATION_CONSTANTS.STATUS_CLOSING_SOON]: "Cierra proximamente • Abierto hoy hasta las {{time}}",
    [TRANSLATION_CONSTANTS.STATUS_OPEN]: "Abierto hoy hasta las {{time}}",
    [TRANSLATION_CONSTANTS.STATUS_CLOSED_OPENSAT]: "Cerrado • Abre a las {{time}}",
    [TRANSLATION_CONSTANTS.FINDING_PROVIDER]: "Buscando proveedor",
    [TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED]: "Proveedor asignado",
    [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE]: "Proveedor en camino",
    [TRANSLATION_CONSTANTS.PROVIDER_ARRIVED]: "Proveedor ha llegado",
    [TRANSLATION_CONSTANTS.PICKING_REPLACEMENT]: "Recogiendo el reemplazo",
    [TRANSLATION_CONSTANTS.PICKING_REPLACEMENT_DESC]: "Su proveedor de servicios está de camino para recoger su vehículo de reemplazo. Pronto estará en camino a su ubicación.",
    [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_DESC]: "Su proveedor de servicios está en camino con su vehículo de reemplazo. Quédese con su vehículo hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_DESC]: "Su rescate ha finalizado y su vehículo ha sido entregado a la empresa de alquiler.",
    [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF]: "Llegada del pasajero",
    [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF_DESC]: "El pasajero ha sido dejado en la ubicación de dejada del pasajero.",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_PASSENGER_DESC]: "Su rescate se ha completado y su vehículo ha sido entregado.",
    [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF_LOCATION]: "Lugar de llegada del pasajero",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE]: "Rescate completo",
    [TRANSLATION_CONSTANTS.PROVIDER_TEN_MINS_ALERT]: "Su proveedor de servicios está a unos 10 minutos de su ubicación. Quédese en su vehículo hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.REPLACEMENT_VEHICLE_DELIVERY]: "Entrega de vehículos de reemplazo",
    [TRANSLATION_CONSTANTS.AVERAGE_ESTIMATED_CHARGES]: "Average Estimated Charges",
    [TRANSLATION_CONSTANTS.ADDITIONAL_COSTS]: "Costs may vary based on Service Provider market rates, and tow mileage if applicable.",
    [TRANSLATION_CONSTANTS.TOWING_CHARGES_NOT_APPLY]: "Towing charges will not apply if there is a vehicle mechanical defect.",
    [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_TITLE]: "Rescue in progress",
    [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_DESC]: "View the rescue tracker or chat with a representative to check the status of your rescue. Your current rescue must be completed before requesting a new one",
    [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_SMS_RT_BUTTON_TEXT]: "view rescue tracker",
    [TRANSLATION_CONSTANTS.PETA_ALERT]: "Basado en su ubicación y la hora del día, la llegada estimada de su proveedor es de entre {{petaFrom}} - {{petaTo}}",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_REVERSE_TOW]: "Your rescue is complete and your vehicle has been delivered.",
    [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_PASSENGER]: "Su  proveedor de servicios  está en camino. Continúe en su vehículo hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.VAS_FUEL_COVERED]: "Your roadside benefits cover the cost of fuel.",
    [TRANSLATION_CONSTANTS.VAS_FUEL_NOT_COVERED]: "Fuel Delivery service does not include the price of the fuel and you would have to pay that to the service provider.",
    [TRANSLATION_CONSTANTS.COVERS_UPTO]: "Cubre hasta",
    [TRANSLATION_CONSTANTS.SERVICE_FEE_REDUCTION_REASON]: "* Coverage amounts reflect a $0.55 service fee.",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_ACCIDENT]: "Tuve un accidente",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_VANDALISM]: "Mi vehículo ha sido vandalizado",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_TIRE_WITHOUT_SPARE]: "Tengo una llanta baja",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_MECHANICAL_KEY]: "Me quedé afuera de mi vehículo",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_BATTERY]: "Mi vehículo está atorado",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_OTHER]: "Otra cosa",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_TITLE]: "Por favor, díganos que describe mejor su situación de remolque.",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_REASON_LABEL]: "¿Con qué necesita ayuda?",
    [TRANSLATION_CONSTANTS.PEP_BOYS_OFFERS]: "Ofertas de Pep Boys",
    [TRANSLATION_CONSTANTS.PEP_BOYS_COUPON]: "Explore cupones de ahorro exclusivos.",
    [TRANSLATION_CONSTANTS.CONTRACT_CANCELLED_MESSAGE]: "Su cobertura fue cancelada pero todavía podemos ayudarle. Use nuestra opción de pago por uso para obtener ayuda en carretera hoy mismo.",
    [TRANSLATION_CONSTANTS.CONTRACT_EXPIRED_MESSAGE]: "Su cobertura expiró pero todavía podemos ayudarle. Use nuestra opción de pago por uso para obtener ayuda en carretera hoy mismo.",
    [TRANSLATION_CONSTANTS.CONTRACT_NOT_ACTIVE_YET_MESSAGE]: "Su cobertura todavía no está activa, pero podemos ayudarle. Use nuestras opciones de pago por uso para obtener ayuda en carretera hoy mismo",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE_POLICY]: "We couldn't find your benefits with the details you provided. Try again using your policy number.",
    [TRANSLATION_CONSTANTS.NEW_JOB_DATA_DRIVEN_ETA_DESC]: "Tenga en cuenta la hora estimada de llegada y permanezca en un lugar seguro hasta que llegue la ayuda.",
    [TRANSLATION_CONSTANTS.BOOKED_DATA_DRIVEN_ETA_DESC]: "El proveedor asignado llegará a la hora prevista.",
    [TRANSLATION_CONSTANTS.BOOKED_NOT_DATA_DRIVEN_ETA_DESC]: "Hemos actualizado la hora de llegada con un estimado más preciso basado en el estatus del proveedor.",
    [TRANSLATION_CONSTANTS.CARD_RETRY_MESSAGE]: "We’re unable to process your payment. Please edit your card information.",
    [TRANSLATION_CONSTANTS.TOW_REASON_NOT_COVERED_MSG]: "The tow reason you selected isn’t covered by your roadside benefits. Use our pay-per-use guest option to get roadside help today.",
    [TRANSLATION_CONSTANTS.COMPLIANCE_CONFIRMATION_SCHEDULED_SERVICE]: "Al seleccionar <strong>continuar<strong>, acepta recibir mensajes de texto sobre el estatus de su rescate."
}
